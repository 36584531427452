import React, { useState, useContext } from "react";
import styles from "../admin.module.scss";
import avatar from "assets/images/default.svg";
import { ReactComponent as UploadIcon } from "assets/images/upload.svg";

import {
  Button,
  InputField,
  TextField,
  Snackbar,
  DatePicker,
  Checkbox,
} from "components";
import AuthServices from "api/services/auth-services";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { GlobalContext, actions } from "context";
import {
  getErrorMessage,
  isEmailValid,
  trimObjectValues,
} from "utils/validator";
import { Autocomplete, Stack } from "@mui/material";
import { Typography, TextField as TF, styled, Paper } from "@material-ui/core";
import ButtonWithIcon from "components/IconButton/icon-button";
import { convertImageToBase64WithMetadata } from "utils/videoUtils";
import { INPUT_LIMITS } from "utils/constant";
import moment from "moment-timezone";

const fieldErrors = {
  name: "Enter Name",
  email: "Enter Email",
  password: "Enter Password",
  confirm_password: "Enter confirm Password",
  phone: "Enter phone number",
  date_joining: "Enter a valid date of joining",
  qa_user: "Choose Role",
  country: "Select Country",
  state: "Select State",
};

const AutoCompleteTextField = styled(TF)({
  "& .MuiOutlinedInput-root": {
    padding: ".25rem .3rem !important",
    borderBottom: "1.5px solid #D0D0D0",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "0",
    fontSize: ".9rem",
    "& fieldset": {
      border: "none",
    },
  },
});

const StyledPaper = styled(Paper)({
  boxShadow: "none !important",
  fontSize: "0.9rem",
  "& .MuiAutocomplete-listbox": {
    padding: 0,
    "& .MuiAutocomplete-option": {
      marginTop: "2px",
      backgroundColor: "white",
      border: "1px solid #c6c6c6",
      fontSize: "13px",
      textTransform: "capitalize",
    },
  },
});

const AddAdmin = ({ handleClose, open, getData }) => {
  const [newAdmin, setNewAdmin] = useState({ qa_user: "Admin" });
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState();
  const {
    dispatch,
    state: { country_state },
  } = useContext(GlobalContext);
  const COUNTRY_LIST = Object.keys(country_state);
  const STATE_LIST = country_state[newAdmin?.country || ""] || [];
  const [isInvalidForm, setIsInvalidForm] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [passwordMissmatch, setPasswordMissmatch] = useState(false);
  const [minLengthInvalid, setMinLengthInvalid] = useState(false);
  const [phoneLenInvalid, setPhoneLenInvalid] = useState(null);
  const [altPhoneLenInvalid, setAltPhoneLenInvalid] = useState(null);

  const addNewAdmin = (key, value) => {
    const val = value;
    const data = {
      ...newAdmin,
      [key]: val,
    };
    setNewAdmin(data);
  };

  const validate = (e) => {
    e?.preventDefault();
    const trimedValue = trimObjectValues(newAdmin);
    let isInvalid = false;
    const inputFields = Object.keys(fieldErrors);
    inputFields.forEach((item) => {
      if (!trimedValue[item]) {
        isInvalid = true;
        setIsInvalidForm(true);
        setNewAdmin(trimedValue);
        return;
      }

      if (item === "date_joining") {
        const dob = moment(trimedValue?.dob);
        const doj = moment(trimedValue?.date_joining);
        if (doj.isValid() && trimedValue?.dob) {
          if (dob.isSameOrAfter(doj, "day")) {
            isInvalid = true;
            setIsInvalidForm(true);
            addNewAdmin("date_joining", null);
            fieldErrors.date_joining =
              "The date of joining must be later than the date of birth.";
            return;
          }
        }
      }
    });
    if (!isInvalid) {
      const { password, confirm_password, email } = newAdmin;
      const isValidEmail = isEmailValid(email);
      setInvalidEmail(!isValidEmail);
      if (
        password !== confirm_password ||
        !isValidEmail ||
        password.length < 8 ||
        password.length > 15 ||
        minLengthInvalid ||
        altPhoneLenInvalid ||
        phoneLenInvalid
      ) {
        setIsInvalidForm(true);
      } else {
        setIsInvalidForm(false);
        setInvalidEmail(false);
        addAdmin();
      }
    }
  };

  const addAdmin = async () => {
    const base64Image = imageFile
      ? await convertImageToBase64WithMetadata(imageFile)
      : null;
    const payload = {
      ...newAdmin,
      profile: base64Image?.base64,
      content_type: base64Image?.content_type,
      filename: base64Image?.filename,
      ...(newAdmin.dob && { dob: dateFormat(newAdmin.dob) }),
      ...(newAdmin.date_joining && {
        date_joining: dateFormat(newAdmin.date_joining),
      }),
    };

    payload.qa_user = payload.qa_user === "Admin" ? false : true;

    storeHandler(actions.SHOW_LOADER, true);
    try {
      const trimedValue = trimObjectValues(payload);
      const { message } = await AuthServices.createUser(trimedValue);
      storeHandler(actions.SHOW_LOADER, false);

      setShowSnackBar(true);
      const content = {
        message: message,
        class: "success",
      };
      setSnackBarContent({ ...content });
    } catch (err) {
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const dateFormat = (val) => {
    const [month, date, year] = new Date(val).toLocaleDateString().split("/");
    return `${year}/${month}/${date}`;
  };

  const addImg = (event) => {
    if (
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      console.error("No file selected or invalid event target");
      return;
    }

    const img = event.target.files[0];

    if (!["image/jpeg", "image/jpg", "image/png"].includes(img.type)) {
      console.error("Selected file is not a JPEG or JPG image");
      setInvalidFileSize(true);
      return;
    }

    const fileSizeMB = img.size / 1024 / 1024;
    const isValidFileSize = fileSizeMB <= 2;

    if (isValidFileSize) {
      setImage(URL.createObjectURL(img));
      setImageFile(img);
      setInvalidFileSize(false);
    } else {
      setInvalidFileSize(true);
    }
  };

  const removeImg = () => {
    setImage(null);
    setImageFile(null);
    setInvalidFileSize(false);
  };

  const emailValidator = (event) => {
    const value = event.target.value;
    setInvalidEmail(!isEmailValid(value));
  };

  const textValidator = (event) => {
    const value = event.target.value;
    const invalid = value.length < 3 || value.length > 30;
    setMinLengthInvalid(invalid);
  };

  const phoneValidator = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const invalid = value.length < 10 || value.length > 15;

    if (name === "phone") {
      if (value) {
        setPhoneLenInvalid(invalid);
      } else {
        setPhoneLenInvalid(null);
      }
    }

    if (name === "alternate_phone") {
      if (value) {
        setAltPhoneLenInvalid(invalid);
      } else {
        setAltPhoneLenInvalid(null);
      }
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const validateConfirmPassword = () => {
    setPasswordMissmatch(
      !!newAdmin["confirm_password"] &&
        newAdmin["confirm_password"] !== newAdmin["password"]
    );
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "1rem",
          },
        }}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={".5rem"}
            mb={"1.5rem"}
          >
            <Stack direction="row" alignItems="center" gap="1rem">
              <p
                className="txt-primary txt-xlg m-0 px-md-3"
                style={{ fontWeight: "500" }}
              >
                Add User
              </p>
            </Stack>
            <ButtonWithIcon
              startIcon={<CloseIcon />}
              style={{ padding: ".5rem 1rem" }}
              onClick={handleClose}
            >
              Close
            </ButtonWithIcon>
          </Stack>
          <form autoComplete="off" onSubmit={validate}>
            <div className="row pb-4">
              <div className="d-flex flex-wrap px-0 px-md-3 col-md-8 col-12">
                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md required-label">Name</label>
                  <TextField
                    variant="filled"
                    className={"mr-3"}
                    fullWidth
                    placeholder="Full Name"
                    alphanumeric={true}
                    onBlur={textValidator}
                    value={newAdmin.name || ""}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value.length <= INPUT_LIMITS.NAME) {
                        addNewAdmin("name", value);
                      }
                    }}
                    helperText={
                      (isInvalidForm &&
                        !newAdmin["name"] &&
                        fieldErrors["name"]) ||
                      (minLengthInvalid &&
                        (newAdmin["name"]?.length > 30
                          ? "Enter less than 30 characters"
                          : "Enter minimum 3 characters"))
                    }
                  />
                </div>
                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md required-label">
                    Email
                  </label>
                  <InputField
                    variant="filled"
                    className={"mr-3"}
                    fullWidth
                    placeholder="Email Id"
                    onBlur={emailValidator}
                    value={newAdmin.email || ""}
                    onChange={(event) => {
                      const email = event.target.value;
                      if (email.length <= INPUT_LIMITS.EMAIL) {
                        addNewAdmin("email", email?.toLowerCase());
                      }
                    }}
                    helperText={
                      (isInvalidForm &&
                        !newAdmin["email"] &&
                        fieldErrors["email"]) ||
                      (invalidEmail && "Enter valid email id")
                    }
                  />
                </div>
                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md required-label">
                    Country
                  </label>
                  <Autocomplete
                    disableClearable
                    value={COUNTRY_LIST.find(
                      (option) => option === newAdmin["country"]
                    )}
                    onChange={(_, value) => {
                      addNewAdmin("country", value);
                    }}
                    options={COUNTRY_LIST}
                    renderInput={(params) => (
                      <AutoCompleteTextField
                        {...params}
                        size="small"
                        variant="outlined"
                        placeholder="Select Country"
                        helperText={
                          isInvalidForm &&
                          !newAdmin["country"] &&
                          fieldErrors["country"]
                        }
                      />
                    )}
                    componentsProps={{
                      paper: {
                        component: StyledPaper,
                      },
                    }}
                  />
                </div>
                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md required-label">
                    State
                  </label>
                  <Autocomplete
                    disableClearable
                    value={STATE_LIST?.find(
                      (option) => option === newAdmin["state"]
                    )}
                    onChange={(_, value) => {
                      addNewAdmin("state", value);
                    }}
                    options={STATE_LIST}
                    renderInput={(params) => (
                      <AutoCompleteTextField
                        {...params}
                        size="small"
                        variant="outlined"
                        placeholder="Select State"
                        helperText={
                          isInvalidForm &&
                          !newAdmin["state"] &&
                          fieldErrors["state"]
                        }
                      />
                    )}
                    componentsProps={{
                      paper: {
                        component: StyledPaper,
                      },
                    }}
                  />
                </div>
                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md">Address</label>
                  <InputField
                    variant="filled"
                    className={"mr-3"}
                    fullWidth
                    placeholder="Add Address"
                    value={newAdmin.address || ""}
                    onChange={(event) => {
                      const address = event.target.value;
                      if (address.length <= INPUT_LIMITS.ADDRESS) {
                        addNewAdmin("address", address);
                      }
                    }}
                  />
                </div>

                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md">ZIP</label>
                  <TextField
                    variant="filled"
                    className={"mr-3"}
                    type="number"
                    fullWidth
                    placeholder="City zip code"
                    value={newAdmin.zipcode || ""}
                    onChange={(event) => {
                      const zipcode = event.target.value;
                      if (zipcode.length <= INPUT_LIMITS.ZIP) {
                        addNewAdmin("zipcode", zipcode);
                      }
                    }}
                  />
                </div>
                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md required-label">
                    Phone
                  </label>
                  <TextField
                    variant="filled"
                    className={"mr-3"}
                    type="number"
                    name="phone"
                    fullWidth
                    onBlur={phoneValidator}
                    placeholder="Mobile Number"
                    value={newAdmin.phone || ""}
                    onChange={(event) => {
                      const phone = event.target.value;
                      if (phone.length <= INPUT_LIMITS.PHONE) {
                        addNewAdmin("phone", phone);
                      }
                    }}
                    helperText={
                      (isInvalidForm &&
                        !newAdmin["phone"] &&
                        fieldErrors["phone"]) ||
                      (phoneLenInvalid === true &&
                        "Phone Number must be between 10 and 15 digits.")
                    }
                  />
                </div>

                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md">Alternate Phone</label>
                  <TextField
                    variant="filled"
                    className={"mr-3"}
                    type="number"
                    fullWidth
                    onBlur={phoneValidator}
                    name="alternate_phone"
                    placeholder="Alternate Mobile Number"
                    value={newAdmin.alternate_phone || ""}
                    onChange={(event) => {
                      const alternate_phone = event.target.value;
                      if (alternate_phone.length <= INPUT_LIMITS.PHONE) {
                        addNewAdmin("alternate_phone", alternate_phone);
                      }
                    }}
                    helperText={
                      altPhoneLenInvalid === true &&
                      "Phone Number must be between 10 and 15 digits."
                    }
                  />
                </div>
                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md">Date of Birth</label>
                  <DatePicker
                    className="mt-0"
                    value={newAdmin?.dob || null}
                    onChange={(val) => addNewAdmin("dob", val)}
                  />
                </div>

                <div className="col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md required-label">
                    Date of Joining
                  </label>
                  <DatePicker
                    className="mt-0"
                    value={newAdmin?.date_joining || null}
                    onChange={(val) => addNewAdmin("date_joining", val)}
                    helperText={
                      isInvalidForm &&
                      !newAdmin["date_joining"] &&
                      fieldErrors["date_joining"]
                    }
                  />
                </div>
              </div>
              <div className="align-items-center col-12 col-md-4 d-flex flex-column flex-wrap">
                <div
                  className={`d-flex flex-column justify-content-between ${styles.addImg}`}
                >
                  <div className="text-center">
                    {" "}
                    <img
                      src={image ? image : avatar}
                      alt=""
                      className={styles.upload}
                    />
                  </div>

                  {!image && (
                    <ButtonWithIcon startIcon={<UploadIcon />}>
                      Upload Image
                      <TextField
                        className={styles.uploadImg}
                        inputProps={{
                          type: "file",
                          accept: "image/*",
                          id: "uploadImg",
                        }}
                        onChange={addImg}
                      />
                    </ButtonWithIcon>
                  )}

                  {image && (
                    <ButtonWithIcon
                      onClick={removeImg}
                      startIcon={<CloseIcon />}
                    >
                      Remove Image
                    </ButtonWithIcon>
                  )}
                </div>
                <Typography
                  variant="caption"
                  className="my-1"
                  style={{ color: "var(--text-disabled)" }}
                >
                  Image size should be 2MB Max
                </Typography>
                {invalidFileSize && (
                  <Typography
                    variant="caption"
                    style={{ color: "var(--error-main)" }}
                  >
                    Upload a .jpg, .jpeg or png image up to 2 MB.
                  </Typography>
                )}

                <Stack direction="row" className="mt-3" gap={1.5}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Checkbox
                      size="small"
                      checked={newAdmin["qa_user"] === "Admin"}
                      onChange={() => addNewAdmin("qa_user", "Admin")}
                    />
                    <Typography>Admin</Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap={1}>
                    <Checkbox
                      size="small"
                      checked={newAdmin["qa_user"] === "QA"}
                      onChange={() => addNewAdmin("qa_user", "QA")}
                    />
                    <Typography>QA</Typography>
                  </Stack>
                </Stack>
              </div>

              <div className="d-flex flex-wrap col-10">
                <div
                  className={`${styles.passwordsec} txt-primary txt-xmd col-9 mx-3 mb-3`}
                >
                  Create Password
                </div>
              </div>

              <div className="d-flex flex-wrap col-12">
                <div className="col-md-4 col-sm-6 col-16 mb-3">
                  <label className="txt-bold txt-md required-label">
                    Password
                  </label>
                  <TextField
                    variant="filled"
                    className={"mr-3"}
                    passwordfield={true}
                    fullWidth
                    placeholder="Enter Password"
                    onBlur={validateConfirmPassword}
                    onChange={(event) =>
                      addNewAdmin("password", event.target.value)
                    }
                    helperText={
                      isInvalidForm &&
                      (!newAdmin["password"] && fieldErrors["password"]
                        ? "Password is required."
                        : newAdmin?.password?.length < 8
                        ? "Your password must be at least 8 characters long."
                        : newAdmin?.password?.length > 15
                        ? "Your password cannot exceed 15 characters."
                        : "")
                    }
                  />
                </div>
                <div className="col-md-4 col-sm-6 col-12 mb-3">
                  <label className="txt-bold txt-md required-label">
                    Confirm Password
                  </label>
                  <TextField
                    variant="filled"
                    className={"mr-3"}
                    passwordfield={true}
                    fullWidth
                    placeholder="Confirm Password"
                    onBlur={validateConfirmPassword}
                    onChange={(event) =>
                      addNewAdmin("confirm_password", event.target.value)
                    }
                    helperText={
                      (isInvalidForm &&
                        !newAdmin["confirm_password"] &&
                        fieldErrors["confirm_password"]) ||
                      (passwordMissmatch &&
                        newAdmin["confirm_password"] !== newAdmin["password"] &&
                        "Password Mismatch")
                    }
                  />
                </div>
                <div className="col-12 text-center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      padding: ".5rem 1rem",
                      background:
                        "linear-gradient(100deg, var(--error-main) 0%, rgba(255,135,135,1) 55%, var(--error-main) 100%)",
                    }}
                  >
                    Create User
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={(val) => {
          setShowSnackBar(val);
          if (snackbarContent?.class !== "error") {
            handleClose();
            getData();
          }
        }}
      />
    </>
  );
};

export default AddAdmin;
