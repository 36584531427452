import React from "react";

import { Checkbox } from "components";
import { Stack } from "@mui/material";

import thumbnail_default from "assets/images/thumbnail.png";
import InfoIcon from "assets/images/info.png";
import { ReactComponent as GreenCheckIcon } from "assets/images/green_checkbox.svg";
import styles from "./RecentDeleteCard.module.scss";
import { convertSecondsToHMS } from "utils/videoUtils";
import moment from "moment-timezone";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    fontSize: ".7rem",
    color: "black",
    boxShadow: theme.shadows[1],
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip classes={classes} {...props} />;
}

const RecentDeleteCard = ({ info, selectedItems, handleToggleItem }) => {
  const {
    id,
    thumbnail,
    duration,
    file_name,
    created_date,
    deleted_date,
    in_days,
  } = info || {};

  const handImgLoadError = (event) => {
    event.target.src = thumbnail_default;
  };

  return (
    <div className={`${styles.videoCard}`} style={{ height: "100%" }}>
      <div className={`${styles.videoPreview}`}>
        <img
          src={thumbnail || thumbnail_default}
          alt="preview"
          className={`${styles.preview} cursor`}
          loading="lazy"
          onError={handImgLoadError}
        />
        <div
          className={`${styles.videoPreviewOverlay} d-flex align-items-start`}
        >
          <div
            className="d-flex justify-content-between flex-grow-1"
            style={{ height: "2rem" }}
          >
            <div className={`${styles.check}`}>
              <div style={{ transform: "scale(1)" }}>
                <Checkbox
                  checkedIcon={
                    <GreenCheckIcon style={{ width: "15px", height: "15px" }} />
                  }
                  checked={selectedItems.has(id)}
                  onChange={() => handleToggleItem(id)}
                />
              </div>
              {!!in_days && (
                <Typography
                  variant="body2"
                  style={{ color: "white", fontSize: ".7rem" }}
                >
                  {in_days} Days
                </Typography>
              )}
            </div>
            <div
              className="txt-white d-flex align-items-center txt-md"
              style={{
                background: "var(--error-main)",
                padding: "0 .3rem",
                fontSize: ".7rem",
              }}
            >
              {convertSecondsToHMS(duration)}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.videoCardBoxWidth}`}>
        <div
          className={`txt-smd py-2 px-2 d-flex justify-content-between`}
          style={{ background: "black" }}
        >
          <span
            style={{
              wordBreak: "break-all",
              paddingRight: "10px",
              color: "white",
            }}
          >
            {file_name}
          </span>
          <BootstrapTooltip
            title={
              <>
                <Stack>
                  <Typography variant="caption">
                    Created Date:{" "}
                    {created_date
                      ? moment(created_date).format("MM/DD/YYYY (z)")
                      : "-"}{" "}
                  </Typography>
                  <Typography variant="caption">
                    Deleted Date:{" "}
                    {deleted_date
                      ? moment(deleted_date).format("MM/DD/YYYY (z)")
                      : "-"}{" "}
                  </Typography>
                </Stack>
              </>
            }
            placement="left-start"
          >
            <img
              src={InfoIcon}
              alt="Info"
              width={"'13px"}
              height={"13px"}
              className="cursor"
            />
          </BootstrapTooltip>
        </div>
      </div>
    </div>
  );
};

export default RecentDeleteCard;
