import React from "react";
import { Button } from "components";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
} from "@material-ui/core";
import styles from "./confirm-dialog.module.scss";
import "./styles.scss";

const ConfirmDialog = ({
  onClose,
  open,
  onConfirm,
  message,
  isPrompt,
  isDeleteVideo,
  count,
  isDownloadVideo,
  customDelete,
  className,
}) => {
  const handleClickInsideDialog = (event) => {
    event.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      className={`${styles.confirmDialog} ${className}`}
      maxWidth={"sm"}
      PaperProps={{
        style: {
          borderRadius: ".5rem",
        },
      }}
      onClick={handleClickInsideDialog}
    >
      <DialogContent>
        <DialogContentText className={styles.dialogContent}>
          <Typography className="text-center" variant="h6">
            {message}
          </Typography>
          {(isDeleteVideo || isDownloadVideo) && (
            <>
              {" "}
              {!!count ? (
                <>
                  {!customDelete && (
                    <p className="txt-grey-drk txt-lg txt-bold text-center my-0">
                      {count} video selected
                    </p>
                  )}
                  {/* {isDeleteVideo && (
                  <p className="txt-primary txt-lg text-center my-0 txt-medium">
                    This action cannot be undone.
                  </p>
                )} */}
                  {customDelete && (
                    <p className="txt-primary txt-lg text-center my-0 txt-medium">
                      Are you Sure you want to delete this video?
                    </p>
                  )}
                  {!customDelete && (
                    <p className="txt-primary txt-lg text-center my-0 txt-medium">
                      Are you Sure you want to{" "}
                      {isDeleteVideo ? "delete" : "download"}{" "}
                      {count > 1 ? "these videos" : "this video"} ?
                    </p>
                  )}
                </>
              ) : (
                <p className="txt-grey-drk txt-lg txt-bold text-center my-0">
                  Please Select the videos to{" "}
                  {isDeleteVideo ? "delete" : "download"}
                </p>
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.footerSec}>
        {isPrompt || ((isDeleteVideo || isDownloadVideo) && !count) ? (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              onClose(false);
            }}
            style={{ borderRadius: "2rem" }}
          >
            Ok
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                onConfirm();
              }}
              disableElevation={true}
              className={isDeleteVideo ? styles.errorBtn : "btn"}
              style={{ borderRadius: "2rem" }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
              style={{ borderRadius: "2rem" }}
            >
              No
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
