import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Stack,
} from "@mui/material";
import { Checkbox, InputField } from "components";
import { Collapse } from "@material-ui/core";
import "./GalleryFilterPopover.scss";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useLocation } from "react-router-dom";

const getUniqueNames = (data) => {
  const names = [];

  data?.forEach((item, index) => {
    try {
      if (typeof item !== "object" || item === null) {
        console.warn(`Item at index ${index} is not an object or is null.`);
        return;
      }

      if (!("user_name" in item)) {
        console.warn(
          `Item at index ${index} does not have a "user_name" field.`
        );
        return;
      }

      names.push(item.user_name);
    } catch (error) {
      console.warn(
        `Error processing item at index ${index}: ${error?.message}`
      );
    }
  });

  const uniqueNames = [...new Set(names)];
  return uniqueNames;
};

function GalleryFilterPopover({
  anchorEl,
  handleClose,
  handleFilterChange,
  check,
  onChange,
  value,
  data,
}) {
  const role = ["approved", "rejected"];

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const video = queryParams.get("video");
  const user_id = queryParams.get("user_id");

  const [openCollapse, setOpenCollapse] = useState(false);

  const handleSearchChange = (value) => {
    handleFilterChange(value);
  };

  const handleToggleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <Box className="Download-popover-root">
      <Popover
        id={"gallery-filter-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: "20rem",
            },
          },
        }}
      >
        <div className="popover-content" style={{ minWidth: 180 }}>
          <List>
            {role.map((name) => (
              <ListItem style={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={".8rem"}
                      >
                        <Typography
                          variant="body2"
                          textTransform={"capitalize"}
                        >
                          {name}
                        </Typography>
                        <Checkbox
                          size="small"
                          checked={check.includes(name)}
                          onChange={() => handleSearchChange(name)}
                        />
                      </Stack>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>

        {!video && !user_id && (
          <div className="popover-content" style={{ minWidth: 180 }}>
            <Typography
              variant="subtitle1"
              fontSize={".9rem"}
              sx={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontWeight: "bold",
                cursor: "pointer",
                py: 0.5,
                px: 1,
              }}
              onClick={handleToggleCollapse}
            >
              Contractors
              {openCollapse ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Typography>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <Box display={"flex"} alignItems={"center"} my={0.5}>
                <InputField
                  variant="outlined"
                  className={`p-0 m-0`}
                  placeholder="Search by Name or Id"
                  withAdronment={true}
                  value={value}
                  onChange={(e) => onChange(e?.target?.value)}
                />
              </Box>

              <List
                onClick={(e) => {
                  onChange(e?.target?.innerText);
                }}
              >
                {getUniqueNames(data)?.map((contractor, index) => (
                  <ListItem
                    key={index}
                    style={{ cursor: "pointer", padding: "0 .5rem" }}
                  >
                    <ListItemText primary={contractor} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        )}
      </Popover>
    </Box>
  );
}

export default GalleryFilterPopover;
