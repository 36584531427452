import styles from "./header.module.scss";
import avatar from "assets/images/default.svg";
import { ReactComponent as LogoutIcon } from "assets/images/logout.svg";
import { ReactComponent as SettingsIcon } from "assets/images/Settings.svg";
import { ReactComponent as ContractorIcon } from "assets/images/Contractor.svg";
import { ReactComponent as GalleryIcon } from "assets/images/Gallery.svg";
import { ReactComponent as AdminIcon } from "assets/images/admin-ico.svg";
import Logo from "assets/images/Logo.svg";
import { ReactComponent as DashboardIcon } from "assets/images/Dashboard.svg";
import { ReactComponent as Downloads } from "assets/images/Downloads.svg";
import { Button, Menu, Snackbar } from "components";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import {
  Badge,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";
import { getErrorMessage } from "utils/validator";
import TimeZone from "./timezone-settimg/TimeZone";
import moment from "moment-timezone";

const useStyles = makeStyles({
  menuList: {
    padding: 0,
  },
});

const Header = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: { refreshProfile, isAdmin, currentDownload },
  } = useContext(GlobalContext);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const [anchorEl2, setAnchorEl2] = useState(null);

  const { pathname } = useLocation();
  const history = useHistory();

  const [profile, setProfile] = useState({});
  const [profileImg, setProfileImg] = useState();

  const isAdminPath = pathname === "/users";
  const isFreelancer = pathname === "/contractors" || pathname === "/user-info";
  const isDashboard = pathname === "/";
  const isGallery = pathname === "/gallery";
  const isDownload = pathname === "/video-download";

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const [timezone, setTimeZone] = useState(null);
  const [allTimezone, setAllTimeZone] = useState([]);

  const [openTimeZone, setOpenTimezone] = useState(false);

  const handleLogoClick = () => {
    history.push("/");
  };

  const toggleTimeZone = () => {
    setOpenTimezone((pre) => !pre);
  };

  const handleTimeZoneSnackbar = (state, message) => {
    setShowSnackBar(state);
    setSnackBarContent(message);
  };

  const handleClose = () => {
    setAnchorEl2(null);
  };

  const getProfile = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const profile_details = await AuthServices.getAdminProfile();

      localStorage.setItem("isAdmin", !profile_details?.qa_user);
      storeHandler(actions.LOG_IN, {
        isLoggedIn: true,
        isAdmin: !profile_details?.qa_user,
      });

      setProfile(profile_details);
      storeHandler(actions.PROFILE, profile_details);
      storeHandler(actions.SHOW_LOADER, false);
      storeHandler(actions.REFRESH_PROFILE, false);
      setProfileImg(profile_details?.profile);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      logOut();
    }
  };

  const logOut = async () => {
    try {
      localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      getErrorMessage(error);
    }
  };

  useEffect(() => {
    if (timezone !== null) {
      setProfile({});
      getProfile();
    }
  }, [refreshProfile, timezone, allTimezone]);

  const getTimeZone = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getTimeZone();
      const { tz_name } = data || {};
      setTimeZone(tz_name);
      moment.tz.setDefault(tz_name?.tz_name);

      storeHandler(actions.TIME_ZONE, tz_name);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      setTimeZone("");
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      handleTimeZoneSnackbar(true, content);
    }
  };

  const getAllTimeZone = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getAllTimeZone();
      setAllTimeZone(data);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      handleTimeZoneSnackbar(true, content);
    }
  };

  useEffect(() => {
    getTimeZone();
    getAllTimeZone();
  }, []);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Paper
      variant="outlined"
      style={{
        borderRadius: "0 0 .6rem .6rem",
      }}
      className={`${styles.headerWrapper} px-sm-3 py-sm-2 p-2 d-flex justify-content-between flex-wrap`}
    >
      <div className="d-flex">
        <img
          src={Logo}
          alt="logo"
          className={styles.logo}
          onClick={handleLogoClick}
        />
        <div className={`${styles.admin} mt-3`}>
          {profile && (profile?.qa_user ? "(QA)" : "(Admin)")}
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap">
        <Button
          variant="outlined"
          size={"small"}
          disableRipple
          style={{
            backgroundColor: isDashboard ? "#c81e1e" : "transparent",
            border: "none",
          }}
          className={`py-sm-2 py-1 mr-md-4 mr-2 ${
            isDashboard ? "" : "outline-none"
          } ${styles.btn}`}
        >
          <NavLink
            to="/"
            className={`${styles.navLink}`}
            activeClassName={styles.selected}
            style={{
              color: isDashboard
                ? "white"
                : isDark
                ? "var(--primary-main)"
                : "var(--text-primary)",
            }}
            exact={true}
          >
            <DashboardIcon className={`mr-2 ${styles.icon}`} />
            Dashboard
          </NavLink>
        </Button>

        <Button
          variant="outlined"
          size={"small"}
          disableRipple
          style={{
            backgroundColor: isGallery ? "#c81e1e" : "transparent",
            border: "none",
          }}
          className={`py-sm-2 py-1 mr-md-4 mr-2 ${
            isGallery ? "" : "outline-none"
          } ${styles.btn}`}
        >
          <NavLink
            to="/gallery"
            activeClassName={styles.selected}
            exact={true}
            className={` ${styles.navLink}`}
            style={{
              color: isGallery
                ? "white"
                : isDark
                ? "var(--primary-main)"
                : "var(--text-primary)",
            }}
          >
            <GalleryIcon className={`mr-2 ${styles.icon}`} />
            Gallery
          </NavLink>
        </Button>
        <Button
          variant="outlined"
          size={"small"}
          disableRipple
          style={{
            backgroundColor: isFreelancer ? "#c81e1e" : "transparent",
            border: "none",
          }}
          className={`py-sm-2 py-1 mr-md-4 mr-2 ${
            isFreelancer ? "" : "outline-none"
          } ${styles.btn}`}
        >
          <NavLink
            to="/contractors"
            activeClassName={styles.selected}
            exact={true}
            className={`${styles.navLink} ${
              isFreelancer && styles["custom-active"]
            }`}
            style={{
              color: isFreelancer
                ? "white"
                : isDark
                ? "var(--primary-main)"
                : "var(--text-primary)",
            }}
          >
            <ContractorIcon className={`mr-2 ${styles.icon}`} />
            Contractors
          </NavLink>
        </Button>
        {checkBoolean(isAdmin) && (
          <Button
            variant="outlined"
            size={"small"}
            disableRipple
            style={{
              backgroundColor: isDownload ? "#c81e1e" : "transparent",
              border: "none",
            }}
            className={`py-sm-2 py-1 mr-md-4 mr-2 ${
              isDownload ? "" : "outline-none"
            } ${styles.btn}`}
          >
            <NavLink
              to="/video-download"
              activeClassName={styles.selected}
              exact={true}
              className={`${styles.navLink}`}
              style={{
                color: isDownload
                  ? "white"
                  : isDark
                  ? "var(--primary-main)"
                  : "var(--text-primary)",
              }}
            >
              <Badge
                badgeContent={currentDownload}
                color="primary"
                style={{ position: "absolute", right: "3px", top: "3px" }}
              />
              <Downloads className={`mr-2 ${styles.icon}`} />
              Downloads
            </NavLink>
          </Button>
        )}
        {checkBoolean(isAdmin) && (
          <Button
            variant="outlined"
            size={"small"}
            disableRipple
            style={{
              backgroundColor: isAdminPath ? "#c81e1e" : "transparent",
              border: "none",
            }}
            className={`py-sm-2 py-1 mr-md-4 mr-2 ${
              isAdminPath ? "" : "outline-none"
            } ${styles.btn}`}
          >
            <NavLink
              to="/users"
              activeClassName={styles.selected}
              exact={true}
              className={`${styles.navLink}`}
              style={{
                color: isAdminPath
                  ? "white"
                  : isDark
                  ? "var(--primary-main)"
                  : "var(--text-primary)",
              }}
            >
              <AdminIcon className={`mr-2 ${styles.icon}`} />
              Users
            </NavLink>
          </Button>
        )}

        <div
          className={`pl-md-4 pl-3 ${styles.divider} d-flex align-items-center`}
        >
          <Menu
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                marginLeft: "40px",
                marginTop: "15px",
                padding: 0,
              },
            }}
            getContentAnchorEl={null}
            MenuListProps={{
              className: classes.menuList,
            }}
          >
            <div
              className="logout"
              style={{
                display: "flex",
                height: "6rem",
                minWidth: "18rem",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div>
                <img
                  src={profileImg || avatar}
                  width="70px"
                  height="70px"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  alt="user"
                />
              </div>
              <div className="ml-3">
                <Typography variant="h6" className="txt-lg ml-2">
                  {profile?.name || ""}
                </Typography>
                <div style={{ marginTop: "-5px", marginBottom: "15px" }}>
                  <span
                    style={{
                      color: "#C20000",
                    }}
                    className="txt-md ml-2"
                  >
                    {profile?.account_id}
                  </span>
                </div>
              </div>
            </div>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              borderTop={"1px solid gray"}
            >
              {checkBoolean(isAdmin) && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  width={"50%"}
                  borderRight={"1px solid gray"}
                  py={1}
                  onClick={() => {
                    handleClose();
                    toggleTimeZone();
                  }}
                >
                  <SettingsIcon width="1rem" height={"1rem"} />
                  <div
                    style={{
                      color: "#C20000",
                    }}
                    className=" ml-2"
                  >
                    Settings
                  </div>
                </Stack>
              )}

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={logOut}
                width={"50%"}
                borderRight={"1px solid gray"}
                py={1}
              >
                <LogoutIcon width="1rem" height={"1rem"} />
                <div
                  style={{
                    color: "#C20000",
                  }}
                  className=" ml-2"
                >
                  Sign Out
                </div>
              </Stack>
            </Stack>
          </Menu>
          <img
            src={profileImg || avatar}
            alt="dashboard-icon"
            className={`${styles.avatar} cursor`}
            onClick={(event) => setAnchorEl2(event.currentTarget)}
          />
        </div>
      </div>

      {checkBoolean(isAdmin) && openTimeZone && (
        <TimeZone
          open={openTimeZone}
          handleClose={toggleTimeZone}
          handleSnackbar={handleTimeZoneSnackbar}
          allTimezone={allTimezone}
          tz={timezone}
          setTz={setTimeZone}
        />
      )}

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </Paper>
  );
};

export default Header;
