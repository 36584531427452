import React, { useContext, useState } from "react";
import styles from "../outer-comment-box/OuterCommentStyle.module.scss";
import { Stack, TextField, Typography } from "@mui/material";
import { convertSecondsToHMS } from "utils/videoUtils";
import moment from "moment-timezone";
import ProfileIcon from "assets/images/comment-profile.svg";
import CommentListPopover from "./CommentListPopover";
import { Button, Snackbar } from "components";
import { ReactComponent as CloseIcon } from "../../../assets/images/comment-close.svg";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import { getErrorMessage } from "utils/validator";

const sortCommentsByDate = (comments) => {
  if (!Array.isArray(comments) || comments.length === 0) {
    return [];
  }

  return [...comments].sort((a, b) => {
    const dateA = a.time_sec;
    const dateB = b.time_sec;

    return dateA - dateB;
  });
};

const CommentsList = ({ comments, seekToTime, getAllComment }) => {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const { dispatch } = useContext(GlobalContext);

  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");

  const startEditing = (id) => {
    setEditingCommentId(id);
    let filteredData = comments?.filter((item) => item.id === id);
    setEditedCommentText(filteredData?.[0]?.comment);
  };

  const saveComment = () => {
    handleEditComment(editingCommentId, editedCommentText);
    setEditingCommentId(null);
  };

  const cancelEditing = (event) => {
    event?.stopPropagation();
    setEditingCommentId(null);
    setEditedCommentText("");
    setExpanded(null);
  };

  const handleDeleteComment = async (id) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await AuthServices.deleteVideoComment({ id });
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment();
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleEditComment = async (id, comment) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await AuthServices.editVideoComment({ id, comment });
      storeHandler(actions.SHOW_LOADER, false);
      getAllComment();
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Stack className={`${styles.allComment} p-1`}>
      {sortCommentsByDate(comments)?.map(
        ({ id, comment, time_sec, comment_date, added_by, profile }) => (
          <Stack
            className={`${styles.comment}`}
            key={id}
            onClick={() => seekToTime(time_sec)}
            gap={2}
            sx={{
              marginBottom: "1rem",
              border:
                editingCommentId === id
                  ? "1px solid rgba(194, 0, 0, 0.4)"
                  : "none",
              borderRadius: "5px",
              ":hover": {
                boxShadow: "0px 4px 10px 0px rgba(194, 0, 0, 0.15)",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <Stack
                direction="row"
                alignItems="center"
                textTransform="capitalize"
                gap={1}
              >
                <img
                  src={profile || ProfileIcon}
                  alt="profile"
                  width={"30px"}
                  height={"30px"}
                  style={{ objectFit: "cover", borderRadius: "50%" }}
                  onError={(e) => {
                    e.target.src = ProfileIcon;
                  }}
                />
                <Typography variant="body2">{added_by}</Typography>
              </Stack>
              <div>
                {editingCommentId !== id && (
                  <CommentListPopover
                    deleteComment={() => {
                      handleDeleteComment(id);
                    }}
                    editComment={() => {
                      startEditing(id);
                    }}
                    handleChange={handleChange}
                    expanded={expanded}
                    id={id}
                  />
                )}
              </div>
            </Stack>

            {editingCommentId === id ? (
              <TextField
                value={editedCommentText}
                onChange={(e) => {
                  e?.stopPropagation();
                  setEditedCommentText(e.target.value);
                }}
                multiline
                maxRows={10}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "none !important",
                    padding: 0,
                    fontWeight: "600",

                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            ) : (
              <Typography variant="caption" style={{ fontWeight: "600" }}>
                {comment}
              </Typography>
            )}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={
                editingCommentId === id
                  ? "1px solid rgba(194, 0, 0, 0.4)"
                  : "1px solid black"
              }
              pb={1}
            >
              <Typography variant="caption">
                {convertSecondsToHMS(time_sec, false)}
              </Typography>

              <Typography variant="caption">
                {comment_date
                  ? moment(comment_date).format("MM/DD/YYYY (z)")
                  : "-"}
              </Typography>
            </Stack>

            {editingCommentId === id && (
              <Stack direction="row" justifyContent="flex-end" gap={0.5}>
                <Button
                  onClick={(event) => {
                    event?.stopPropagation();
                    event?.preventDefault();
                    saveComment();
                    setExpanded(null);
                  }}
                  variant="contained"
                  disableRipple={true}
                  style={{
                    minWidth: "max-content",
                    padding: ".3rem .5rem",
                    maxWidth: "max-content",
                    fontSize: ".8rem",
                    fontWeight: "200",
                    backgroundColor: "var(--error-main)",
                    color: "white",
                    boxShadow: "none",
                    borderRadius: ".3rem",
                  }}
                >
                  Post
                </Button>

                <Button
                  onClick={cancelEditing}
                  variant="contained"
                  disableRipple={true}
                  style={{
                    minWidth: "max-content",
                    padding: ".3rem",
                    maxWidth: "max-content",
                    fontSize: ".8rem",
                    fontWeight: "200",
                    backgroundColor: "var(--error-main)",
                    color: "white",
                    boxShadow: "none",
                    borderRadius: ".3rem",
                  }}
                >
                  <CloseIcon />
                </Button>
              </Stack>
            )}
          </Stack>
        )
      )}

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </Stack>
  );
};

export default CommentsList;
