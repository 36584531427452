import { useState, useEffect, useContext, useRef } from "react";
import styles from "./admin.module.scss";
import { InputField, Snackbar, UserInfoCard } from "components";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import AuthServices from "api/services/auth-services";
import AddAdmin from "./add-admin/add-admin";
import { GlobalContext, actions } from "context";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { Paper, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import ButtonWithIcon from "components/IconButton/icon-button";
import AdminFilterPopover from "./admin-filter-opover/AdminFilterPopover";
import AdminSortPopover from "./admin-sort-popover/AdminSortPopover";
import { getErrorMessage } from "utils/validator";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function createQueryString(
  filterCriteria,
  locationFilters,
  searchQuery,
  sortCriteria
) {
  const filterCriteriaString = filterCriteria
    .map((item) => {
      const filterType =
        item === "admin" || item === "qa" ? "filter_user" : "filter_status";
      return `${filterType}=${encodeURIComponent(item)}`;
    })
    .join("&");

  const locationFilterString = locationFilters
    .map((item) => {
      return `location=${encodeURIComponent(item)}`;
    })
    .join("&");

  const searchQueryString = `search=${encodeURIComponent(searchQuery)}`;
  const sortQueryString = `sort_name=${encodeURIComponent(
    sortCriteria === "acc" ? "asc" : sortCriteria === "dec" ? "desc" : ""
  )}`;
  const limitString = `limit=${encodeURIComponent(15)}`;

  const queryParts = [
    searchQueryString,
    sortQueryString,
    limitString,
    filterCriteriaString,
    locationFilterString,
  ];

  return queryParts.filter((part) => part).join("&");
}

const Admin = () => {
  const {
    dispatch,
    state: { showLoader },
  } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [adminDataCopy, setAdminDataCopy] = useState([]);
  const [lek, setLek] = useState(undefined);
  const observerRef = useRef(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const getAdminData = async (query) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data, lek } = await AuthServices.getUsers(query);
      setAdminDataCopy((pre) => {
        return [...pre, ...data];
      });
      setLek(lek);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleOpenFilterPopover = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleOpenSortPopover = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const [sortCriteria, setSortCriteria] = useState("");
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [locationFilters, setLocationFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryflag, setSearchQueryFlag] = useState(false);

  let fullQueryString = createQueryString(
    filterCriteria,
    locationFilters,
    searchQuery,
    sortCriteria
  );

  const handleSortChange = (criteria) => {
    setSortCriteria((pre) => (pre === criteria ? "" : criteria));
    setAdminDataCopy([]);
    setLek(undefined);
  };

  const handleFilterChange = (filter) => {
    setFilterCriteria((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
    setAdminDataCopy([]);
    setLek(undefined);
  };

  const handleLocationFilters = (filter) => {
    setLocationFilters((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
    setAdminDataCopy([]);
    setLek(undefined);
  };

  const handleSetSearchQueryFlag = useRef(
    debounce(() => {
      setAdminDataCopy([]);
      setLek(undefined);
      setSearchQueryFlag((pre) => !pre);
    }, 500)
  ).current;

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    handleSetSearchQueryFlag();
  };

  useEffect(() => {
    if (lek === null && adminDataCopy.length > 0) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const lekString = `lek=${encodeURIComponent(lek)}`;
          let QS = fullQueryString;
          if (lek) {
            QS = QS + "&" + lekString;
          }
          getAdminData(QS);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [lek, sortCriteria, filterCriteria, searchQueryflag]);

  const getDataFn = () => {
    setAdminDataCopy([]);
    setLek(undefined);
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceHeader} d-flex justify-content-between align-items-center flex-md-row flex-column `}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "2.3rem", fontWeight: "600" }}
        >
          Users
        </Typography>
        <div>
          <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
            <ButtonWithIcon
              disableRipple
              startIcon={<SortIcon />}
              onClick={handleOpenSortPopover}
            >
              Sort
            </ButtonWithIcon>
            <AdminSortPopover
              anchorEl={sortAnchorEl}
              handleClose={handleSortClose}
              handleSortChange={handleSortChange}
              check={sortCriteria}
            />

            <ButtonWithIcon
              disableRipple
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add User
            </ButtonWithIcon>

            <InputField
              value={searchQuery}
              variant="filled"
              placeholder="Search by Name or ID"
              withAdronment={true}
              onChange={(event) => {
                if (!!showLoader) return;
                handleSearchChange(event.target.value);
              }}
            />

            <ButtonWithIcon
              disableRipple
              startIcon={<FilterIcon />}
              onClick={handleOpenFilterPopover}
            >
              Filter
            </ButtonWithIcon>
            {filterAnchorEl && (
              <AdminFilterPopover
                anchorEl={filterAnchorEl}
                handleClose={handleFilterClose}
                handleFilterChange={handleFilterChange}
                check={filterCriteria}
                handleLocationFilters={handleLocationFilters}
                locationCheck={locationFilters}
              />
            )}
          </Stack>
        </div>
      </Paper>
      <div
        className={`${styles.freelancerCardWrapper} py-4 d-flex flex-wrap justify-content-center justify-content-md-start`}
      >
        {!!adminDataCopy?.length &&
          adminDataCopy.map((item) => (
            <div className="px-3 my-0">
              <UserInfoCard item={item} admin={true} getData={getDataFn} />
            </div>
          ))}

        {lek !== null && (
          <div
            className="mx-3 my-0"
            ref={observerRef}
            style={{ textAlign: "center", height: "100%" }}
          >
            <div style={{ zIndex: 9999999 }}></div>
          </div>
        )}

        {!adminDataCopy?.length && !showLoader && (
          <p className="txt-xlg txt-primary flex-grow-1 text-center">
            No Users Found
          </p>
        )}
      </div>

      {open && (
        <AddAdmin open={open} handleClose={handleClose} getData={getDataFn} />
      )}

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </div>
  );
};

export default Admin;
