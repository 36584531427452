import { useState, useEffect, useContext, useRef } from "react";
import styles from "./freelancer-info.module.scss";
import Switch from "@material-ui/core/Switch";
import avatar from "assets/images/default.svg";
import callIco from "assets/images/call.svg";
import locationIco from "assets/images/location.svg";
import dateIco from "assets/images/date-ico.svg";
import mailIco from "assets/images/email-ico.svg";
import NoVideo from "assets/images/video-placeholder.svg";
import yetToSync from "assets/images/video-yet-sync.svg";
import synced from "assets/images/video-synced.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import videoIcon from "assets/images/video-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";

import { InputField, ConfirmDialog, Checkbox, Snackbar } from "components";
import EditFreelancer from "./edit-freelancer/edit-freelancer";
import { ReactComponent as DeleteIcon } from "assets/images/delete-outline.svg";
import { useHistory } from "react-router-dom";
import VideoCard from "./video-card/video-card";
import moment from "moment-timezone";
import { Paper, Tooltip, Typography } from "@material-ui/core";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Stack } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";
import FreelancerFilterPopover from "./freelancer-filter-opover/FreelancerFilterPopover";
import FreelancerSortPopover from "./freelancer-sort-popover/FreelancerSortPopover";
import { ReactComponent as DownloadIcon } from "assets/images/bulk_download.svg";
import { ReactComponent as TrashIcon } from "assets/images/bulk_delete.svg";
import { ReactComponent as GreenCheckIcon } from "assets/images/green_checkbox.svg";
import { getErrorMessage } from "utils/validator";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function createQueryString(filterCriteria, searchQuery, sortCriteria, id) {
  const filterCriteriaString = filterCriteria
    .map((item) => `filter_approval=${encodeURIComponent(item)}`)
    .join("&");

  const searchQueryString = searchQuery
    ? `search=${encodeURIComponent(searchQuery)}`
    : "";
  const sortNameQueryString =
    sortCriteria === "acc" || sortCriteria === "dec"
      ? `sort_name=${encodeURIComponent(
          sortCriteria === "acc" ? "asc" : sortCriteria === "dec" ? "desc" : ""
        )}`
      : "";

  const sortDateQueryString =
    sortCriteria === "accd" || sortCriteria === "decd"
      ? `sort_date=${encodeURIComponent(
          sortCriteria === "accd"
            ? "asc"
            : sortCriteria === "decd"
            ? "desc"
            : ""
        )}`
      : "";

  const limitString = `limit=${encodeURIComponent(15)}`;
  const userID = `id=${encodeURIComponent(id)}`;

  const queryParts = [
    userID,
    searchQueryString,
    sortNameQueryString,
    sortDateQueryString,
    limitString,
    filterCriteriaString,
  ];
  return queryParts.filter((part) => part).join("&");
}

function createTimeLineQueryString(id) {
  const limitString = `limit=${encodeURIComponent(3)}`;
  const userID = `id=${encodeURIComponent(id)}`;

  const queryParts = [userID, limitString];
  return queryParts.filter((part) => part).join("&");
}

const FreeLancerInfo = () => {
  const {
    dispatch,
    state: { isAdmin, userId, showLoader },
  } = useContext(GlobalContext);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const IS_ADMIN = checkBoolean(isAdmin);

  const [open, setOpen] = useState(false);
  const [freelancerDetail, setFreelancerDetail] = useState();
  const [videoStatus, setVideoStatus] = useState();
  const history = useHistory();

  const [isActive, setIsActive] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isBulkSelect, setIsBulkSelect] = useState(false);
  const [statusList, setStatus] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  const [openAccountDeletePrompt, setOpenAccountDeletePrompt] = useState(false);

  const [unselectAll, setUnSelectAll] = useState(false);

  const [totalDeletedVideos, setTotalDeletedVideos] = useState(0);

  const [profileImg, setProfileImg] = useState();

  const [videoLists, setVideoLists] = useState([]);

  // video
  const [videoLek, setVideoLek] = useState(undefined);
  const [timeLineLek, setTimeLineLek] = useState(undefined);
  const timeLineObserverRef = useRef(null);
  const observerRef = useRef(null);

  const timelineFormatDate = (date) => {
    if (moment(date, moment.ISO_8601, true).isValid()) {
      return moment(date).format("MM/DD/YYYY HH:mm (z)");
    }
    return "-";
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFreelancerDetails = async () => {
    try {
      storeHandler(actions.SHOW_LOADER, true);
      const { profile_details, video_stats, video } = userId.admin
        ? await AuthServices.getAdminDetail(userId.id)
        : await AuthServices.getFreelancerDetails(userId.id);
      // const profInfo = { ...profile_details };
      // profInfo.video = profInfo?.video?.map((item, i) => ({
      //   ...item,
      //   date: `${new Date(item.date).toDateString()}  ${new Date(
      //     item.date
      //   ).getHours()}:${new Date(item.date).getMinutes()}`,
      // }));
      setFreelancerDetail(profile_details);
      setVideoStatus(video_stats);
      setIsActive(!!profile_details?.status);
      // setVideoLists(video);
      setProfileImg(profile_details.profile);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);

      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const getFreelancerVideo = async (query) => {
    try {
      storeHandler(actions.SHOW_LOADER, true);
      const { data, lek } = userId.admin
        ? await AuthServices.getAdminDetail(userId.id)
        : await AuthServices.getFreelancerVideo(query);
      // const profInfo = { ...profile_details, video };
      // profInfo.video = profInfo?.video?.map((item, i) => ({
      //   ...item,
      //   date: `${new Date(item.date).toDateString()}  ${new Date(
      //     item.date
      //   ).getHours()}:${new Date(item.date).getMinutes()}`,
      // }));
      // setFreelancerDetail(profInfo);
      // setVideoStatus(video_stats);
      // setIsActive(!!profile_details?.status);

      setVideoLists((pre) => {
        return [...pre, ...data];
      });
      setVideoLek(lek);
      storeHandler(actions.SHOW_LOADER, false);
      // setProfileImg(profInfo.profile);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const getUserTimeLine = async (query) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data, lek } = await AuthServices.getUserTimeLine(query);
      setStatus((pre) => {
        return [...pre, ...data];
      });
      setTimeLineLek(lek);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  useEffect(() => {
    getFreelancerDetails();
  }, []);

  const toggleActive = async (checked) => {
    const { admin, id } = userId;

    try {
      storeHandler(actions.SHOW_LOADER, true);
      let response = {};
      if (admin) {
        response = checked
          ? await AuthServices.activateAdmin(id)
          : await AuthServices.deactivateAdmin(id);
      } else {
        response = checked
          ? await AuthServices.activateFreelancer(id)
          : await AuthServices.deactivateFreelancer(id);
      }
      setIsActive(checked);
      storeHandler(actions.SHOW_LOADER, false);
      const { message } = response;
      setShowSnackBar(true);
      const content = {
        message: message,
        class: "success",
      };
      setSnackBarContent({ ...content });
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const selectallVideos = () => {
    if (selectAll) {
      setSelectAll(!selectAll);
      setSelectedVideos([]);
    } else {
      setSelectAll(!selectAll);
      const ids = videoLists?.map((item) => item.id) || [];
      setSelectedVideos(ids);
    }
  };

  const onConfirm = () => {
    deleteVideos();
    setOpenDialog(false);
  };

  const deleteVideos = async (url) => {
    if (!selectedVideos?.length) return;

    storeHandler(actions.SHOW_LOADER, true);
    try {
      await AuthServices.deleteVideo({ id: selectedVideos });
      storeHandler(actions.SHOW_LOADER, false);
      setOpenDeletePrompt(true);
      getFreelancerDetails();
      setUnSelectAll(true);
      setSelectAll(false);
      setTotalDeletedVideos(url ? 1 : selectedVideos?.length);
      setSelectedVideos([]);
      getUserTimeLine(userId?.id);
      !url && updateBulkSelect();
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const onConfirmDialog = () => {
    setOpenConfirmDialog(false);
    toggleActive(!isActive);
  };

  const handleAddVideo = (url) => {
    const currentDownloads =
      JSON.parse(localStorage.getItem("downloadQueue")) || [];
    currentDownloads.push(...url);
    localStorage.setItem("downloadQueue", JSON.stringify(currentDownloads));
    const event = new CustomEvent("localStorageChange", { key: Date.now() });
    window.dispatchEvent(event);
  };

  const onDownloadConfirm = async () => {
    const groupName = `Group_${Date.now()}`;
    const filteredArr = videoLists?.filter((obj) =>
      selectedVideos?.includes(obj?.id)
    );
    filteredArr.forEach((item) => {
      item.group_name = groupName;
      item.completed = false;
      item.error = false;
    });
    let GROUPS = JSON.parse(localStorage.getItem("video_group")) || [];
    GROUPS.push(...filteredArr);

    setOpenDownloadDialog(false);
    updateBulkSelect();
    localStorage.setItem("video_group", JSON.stringify(GROUPS));
    handleAddVideo(filteredArr);
  };

  const onAccountDeleteConfirm = async () => {
    const { admin, id } = userId;
    try {
      storeHandler(actions.SHOW_LOADER, true);

      if (admin) {
        await AuthServices.activateAdmin(id);
      } else {
        await AuthServices.deleteFreelancer(id);
      }
      storeHandler(actions.SHOW_LOADER, false);
      setOpenAccountDeletePrompt(false);
      history.push("/contractors");
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const openPopup = () => {
    setOpenConfirmDialog(true);
  };

  const updateBulkSelect = () => {
    setIsBulkSelect(!isBulkSelect);
    if (isBulkSelect) {
      clearSelection();
      setSelectAll(false);
    }
  };

  const clearSelection = () => {
    setSelectedVideos([]);
    setUnSelectAll(true);
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleOpenFilterPopover = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleOpenSortPopover = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const [sortCriteria, setSortCriteria] = useState("");
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryflag, setSearchQueryFlag] = useState(false);

  let fullQueryString = createQueryString(
    filterCriteria,
    searchQuery,
    sortCriteria,
    userId.id
  );

  const handleSortChange = (criteria) => {
    setSortCriteria((pre) => (pre === criteria ? "" : criteria));
    setVideoLists([]);
    setVideoLek(undefined);
  };

  const handleFilterChange = (filter) => {
    setFilterCriteria((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
    setVideoLists([]);
    setVideoLek(undefined);
  };

  const handleSetSearchQueryFlag = useRef(
    debounce(() => {
      setVideoLists([]);
      setVideoLek(undefined);
      setSearchQueryFlag((pre) => !pre);
    }, 500)
  ).current;

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    handleSetSearchQueryFlag();
  };

  useEffect(() => {
    if (videoLek === null && videoLists.length > 0) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const lekString = `lek=${encodeURIComponent(videoLek)}`;
          let QS = fullQueryString;
          if (videoLek) {
            QS = QS + "&" + lekString;
          }
          getFreelancerVideo(QS);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [videoLek, sortCriteria, filterCriteria, searchQueryflag]);

  useEffect(() => {
    if (timeLineLek === null && statusList.length > 0) return;

    let fullTimeLineQueryString = createTimeLineQueryString(userId.id);

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const lekString = `lek=${encodeURIComponent(timeLineLek)}`;
          let QS = fullTimeLineQueryString;
          if (timeLineLek) {
            QS = QS + "&" + lekString;
          }
          getUserTimeLine(QS);
        }
      },
      { threshold: 1.0 }
    );

    if (timeLineObserverRef.current) {
      observer.observe(timeLineObserverRef.current);
    }

    return () => {
      if (timeLineObserverRef.current) {
        observer.unobserve(timeLineObserverRef.current);
      }
    };
  }, [timeLineLek]);

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceInfoHeader} d-flex justify-content-between align-items-center flex-md-row flex-column`}
      >
        <Stack
          direction={"row"}
          gap={".5rem"}
          alignItems="center"
          className={!IS_ADMIN && styles.header}
        >
          <Tooltip title={freelancerDetail?.name || ""} arrow>
            <Typography
              variant="h4"
              className={styles.typography_container}
              style={{
                fontSize: "2.3rem",
                fontWeight: "600",
                marginRight: "2rem",
              }}
            >
              {freelancerDetail?.name || ""}
            </Typography>
          </Tooltip>

          {IS_ADMIN && (
            <ButtonWithIcon
              disableRipple
              endIcon={
                <FiberManualRecordIcon
                  style={{ color: isActive ? "green" : "var(--error-main)" }}
                />
              }
            >
              Active
            </ButtonWithIcon>
          )}
          {IS_ADMIN && (
            <Switch
              name="checkedA"
              color="primary"
              size="small"
              checked={isActive}
              onClick={openPopup}
            />
          )}
          {IS_ADMIN && (
            <div className="pl-3">
              <ButtonWithIcon
                disableRipple
                startIcon={<EditIcon />}
                onClick={handleClickOpen}
              >
                Edit Contractor
              </ButtonWithIcon>
            </div>
          )}
          <div className="pl-5">
            <InputField
              value={searchQuery}
              variant="outlined"
              placeholder="Search by video name "
              withAdronment={true}
              onChange={(event) => {
                if (!!showLoader) return;
                handleSearchChange(event.target.value);
              }}
            />
          </div>
        </Stack>

        {false && IS_ADMIN && (
          <ButtonWithIcon
            disableRipple
            startIcon={<DeleteIcon />}
            onClick={() => setOpenAccountDeletePrompt(true)}
          >
            Delete
          </ButtonWithIcon>
        )}
      </Paper>

      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        className="p-2 py-3 "
      >
        <Stack
          direction={"row"}
          gap={".2rem"}
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/contractors")}
        >
          <KeyboardBackspaceIcon />
          <Typography variant="subtitle1">Back</Typography>
        </Stack>

        <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
          Account ID:{" "}
          <span style={{ color: "var(--error-main)" }}>
            {freelancerDetail?.account_id || ""}
          </span>
        </Typography>
      </Stack>

      <div className="d-flex flex-wrap">
        <div className={`flex-grow-1 ${styles.infoWrap}`}>
          <div className="d-flex flex-wrap">
            <div
              className={`${styles.userDetails} d-flex justify-content-between flex-grow-1 flex-wrap flex-lg-row flex-column`}
            >
              <div
                className="d-flex"
                style={{
                  background: "black",
                  borderRadius: ".3rem",
                  padding: ".8rem",
                  minWidth: "18rem",
                  maxWidth: "18rem",
                }}
              >
                <div className={`${styles.avatar}`}>
                  <img
                    src={profileImg || avatar}
                    alt={"avatar"}
                    loading="lazy"
                  />
                </div>
                <div className="d-flex flex-column justify-content-between pl-2">
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={callIco} alt="call" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.phone || "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={mailIco} alt="call" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.email || "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={dateIco} alt="location" className="mr-2" />

                    <span className={`${styles.info}`}>
                      {freelancerDetail?.date_joining
                        ? moment(freelancerDetail?.date_joining).format(
                            "MM/DD/YYYY (z)"
                          )
                        : "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={locationIco} alt="location" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.state || "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex-grow-1 d-flex justify-content-lg-end  justify-content-center align-item-end  flex-wrap">
                <div
                  className={`${styles.videoInfoCard} p-3 mr-3 my-2 my-lg-0`}
                >
                  <Typography>Videos Captured</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    p={".3rem"}
                    alignItems="center"
                  >
                    <Typography variant="h2">
                      {videoStatus?.captured}
                    </Typography>
                    <img
                      src={videoIcon}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>

                <div
                  className={`${styles.videoInfoCard} p-3 mr-3 my-2 my-lg-0`}
                >
                  <Typography>Videos Accepted</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    p={".3rem"}
                    alignItems="center"
                  >
                    <Typography variant="h2">
                      {videoStatus?.Accepted}
                    </Typography>
                    <img
                      src={synced}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>

                <div className={`${styles.videoInfoCard} p-3 my-2 my-lg-0`}>
                  <Typography>Videos Rejected</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p={".3rem"}
                  >
                    <Typography variant="h2">
                      {videoStatus?.Rejected}
                    </Typography>
                    <img
                      src={yetToSync}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>
              </div>
            </div>
            {!userId.admin && (
              <>
                <div
                  style={{
                    width: "100%",
                    padding: ".5rem 0",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    my="1rem"
                  >
                    <Typography variant="h5">Videos</Typography>

                    <Stack direction="row" gap="1.5rem">
                      {!!videoLists?.length && IS_ADMIN && (
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          <Checkbox
                            checkedIcon={
                              <GreenCheckIcon
                                style={{ width: "15px", height: "15px" }}
                              />
                            }
                            size="small"
                            checked={
                              selectedVideos?.length === videoLists?.length
                            }
                            onChange={selectallVideos}
                            style={{ transform: "scale(1.2)" }}
                          />
                          <Typography
                            style={{ fontWeight: "500", cursor: "pointer" }}
                            variant="subtitle1"
                          >
                            Select All
                          </Typography>

                          {!!selectedVideos?.length && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={"1rem"}
                              pl={"1rem"}
                            >
                              <DownloadIcon
                                style={{ width: "25px", cursor: "pointer" }}
                                onClick={() => setOpenDownloadDialog(true)}
                              />
                              <TrashIcon
                                style={{ width: "25px", cursor: "pointer" }}
                                onClick={() => setOpenDialog(true)}
                              />
                            </Stack>
                          )}
                        </Stack>
                      )}

                      <ButtonWithIcon
                        disableRipple
                        startIcon={<SortIcon />}
                        onClick={handleOpenSortPopover}
                      >
                        Sort
                      </ButtonWithIcon>
                      <FreelancerSortPopover
                        anchorEl={sortAnchorEl}
                        handleClose={handleSortClose}
                        handleSortChange={handleSortChange}
                        check={sortCriteria}
                      />

                      <ButtonWithIcon
                        disableRipple
                        startIcon={<FilterIcon />}
                        onClick={handleOpenFilterPopover}
                      >
                        Filter
                      </ButtonWithIcon>
                      <FreelancerFilterPopover
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilterChange={handleFilterChange}
                        check={filterCriteria}
                      />
                    </Stack>
                  </Stack>
                </div>
                {!userId.admin && !videoLists?.length && !showLoader && (
                  <div className="text-center flex-grow-1">
                    <img
                      src={NoVideo}
                      alt="no video"
                      className={`${styles.noVideo}`}
                    />
                    <p className="txt-lg txt-primary my-0">
                      {sortCriteria || filterCriteria?.length || searchQueryflag
                        ? "No results found."
                        : "No Recorded Videos found"}
                    </p>
                  </div>
                )}
                <div className="position-relative">
                  <div
                    style={{
                      gap: "3rem",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {videoLists?.map((item, i) => (
                      <div>
                        <VideoCard
                          deleteVideos={deleteVideos}
                          freelancerDetail={freelancerDetail}
                          unselectAll={unselectAll}
                          setUnSelectAll={setUnSelectAll}
                          info={item}
                          selectedVideos={selectedVideos}
                          setSelectedVideos={setSelectedVideos}
                          selectAll={selectAll}
                          index={i}
                          isBulkSelect={isBulkSelect}
                          getFreelancerDetails={() => {
                            setVideoLists([]);
                            let QS = fullQueryString;
                            getFreelancerVideo(QS);

                            setStatus([]);
                            let QS1 = createTimeLineQueryString(userId.id);
                            getUserTimeLine(QS1);
                          }}
                        />
                      </div>
                    ))}

                    {videoLek !== null && (
                      <div
                        className="mx-3 my-0"
                        ref={observerRef}
                        style={{
                          textAlign: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div style={{ zIndex: 9999999, color: "black" }}></div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!userId.admin && (
          <div className={`pl-4 ${styles.timelineWrap} d-none d-lg-block`}>
            <div className={`${styles.timeline} p-3`}>
              <p className="txt-lg txt-primary mb-3 mt-0">Timeline</p>

              {!!statusList?.length &&
                statusList.map((item) => (
                  <div className={`${styles.status} mb-2 pb-2 txt-smd`}>
                    <div className="d-flex align-items-center">
                      <span
                        className={`${styles.statusIndicator} mr-2`}
                        style={{
                          background: item?.approval
                            ? "green"
                            : "var(--error-main)",
                        }}
                      ></span>{" "}
                      <span className="txt-grey">
                        {timelineFormatDate(item?.approval_on)}
                      </span>
                    </div>
                    <div
                      className="txt-success px-4 mt-1"
                      style={{
                        color: item?.approval ? "green" : "var(--error-main)",
                      }}
                    >
                      {item?.approval
                        ? `Approved by ${item?.approval_by}`
                        : `Rejected by ${item?.approval_by}`}
                    </div>
                  </div>
                ))}

              {timeLineLek !== null && (
                <div
                  className="mx-3 my-0"
                  ref={timeLineObserverRef}
                  style={{
                    textAlign: "center",
                    height: "100px",
                    width: "100%",
                  }}
                >
                  <div style={{ zIndex: 9999999, color: "black" }}></div>
                </div>
              )}
            </div>
          </div>
        )}
        {open && (
          <EditFreelancer
            open={open}
            handleClose={handleClose}
            info={freelancerDetail}
            admin={userId.admin}
            getData={() => {
              getFreelancerDetails();
            }}
          />
        )}
        <ConfirmDialog
          isPrompt={false}
          open={openConfirmDialog}
          onClose={setOpenConfirmDialog}
          onConfirm={onConfirmDialog}
          message={`Are you Sure you want to ${
            isActive ? "disable" : "enable"
          } the ${userId.admin ? "admin" : "contractor"}?`}
        />
        <ConfirmDialog
          isPrompt={false}
          open={openDialog}
          onClose={setOpenDialog}
          onConfirm={onConfirm}
          count={selectedVideos.length}
          isDeleteVideo={true}
        />
        <ConfirmDialog
          isPrompt={false}
          open={openDownloadDialog}
          onClose={setOpenDownloadDialog}
          onConfirm={onDownloadConfirm}
          count={selectedVideos.length}
          isDownloadVideo={true}
        />
        <ConfirmDialog
          isPrompt={true}
          open={openDeletePrompt}
          onClose={setOpenDeletePrompt}
          message={`${
            totalDeletedVideos === 1
              ? `1 Video`
              : `${totalDeletedVideos} Videos`
          } deleted successfully`}
        />

        <ConfirmDialog
          isPrompt={false}
          open={openAccountDeletePrompt}
          onClose={setOpenAccountDeletePrompt}
          onConfirm={onAccountDeleteConfirm}
          message={"Are you sure you want to Delete?"}
        />
      </div>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </div>
  );
};

export default FreeLancerInfo;
