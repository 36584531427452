import { useState, useContext, useEffect } from "react";
import { Box, TextField, Button, Snackbar } from "components";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/images/back-icon.svg";
import AuthServices from "api/services/auth-services";
import ChangePassword from "app/change-password/change-password";
import OtpInput from "react-otp-input";
import { GlobalContext, actions } from "context";
import { isEmailValid } from "utils/validator";
import { Paper, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";

const OTP_SENT = "OTP Sent";
const OTP_VERIFIED = "OTP Verified";
const INVALID_EMAIL = "Invalid Email!";
const INVALID_OTP = "Invalid OTP";

const inputValidationMessage = {
  invalidEmail: "Enter valid email id",
  invalidOtp: "Invalid Verification Code",
};

const CountdownTimer = ({ initialTime = 30, onResend, updateCounter }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      setShowButton(true);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleResendClick = () => {
    if (onResend) {
      onResend();
      updateCounter((pre) => pre + 1);
    }

    // Reset timer and button visibility
    setTimeLeft(initialTime);
    setShowButton(false);
  };

  return (
    <div style={{ color: "var(--error-main)" }}>
      {showButton ? (
        <span className="txt-bold cursor" onClick={handleResendClick}>
          Resend code
        </span>
      ) : (
        <span className="txt-bold">Resend code in {timeLeft}s</span>
      )}
    </div>
  );
};

const ForgotPassword = () => {
  const { dispatch } = useContext(GlobalContext);

  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [resendOtpCount, setResendOtpCount] = useState(0);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const generateOtp = async (e) => {
    e?.preventDefault();
    setOtp("");
    if (resendOtpCount >= 3) {
      return;
    }

    setShowSnackBar(false);
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const { message } = await AuthServices.getOtp({ email });

      if (message === OTP_SENT) {
        otpSuccessNotification();
        setShowVerifyOtp(true);
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      if (message === INVALID_EMAIL) {
        const data = {
          message: inputValidationMessage.invalidEmail,
          class: "error",
        };
        setShowSnackBar(true);
        setSnackBarContent({ ...data });
      }
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const otpSuccessNotification = () => {
    const data = { message: "OTP Sent Successfully", class: "success" };
    setShowSnackBar(true);
    setSnackBarContent({ ...data });
  };
  const verifyOtp = async (e) => {
    e?.preventDefault();
    setShowSnackBar(false);
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const { message } = await AuthServices.verifyOtp({ email, otp });
      if (message === OTP_VERIFIED) {
        setShowChangePassword(true);
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      if (message === INVALID_OTP) {
        const data = {
          message: inputValidationMessage.invalidOtp,
          class: "error",
        };
        setShowSnackBar(true);
        setSnackBarContent({ ...data });
      }
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const emailValidator = (event) => {
    const value = event.target.value;
    setInvalidEmail(!isEmailValid(value));
  };

  if (showChangePassword) {
    return <ChangePassword email={email} />;
  }

  return (
    <Paper
      variant="outlined"
      style={{
        padding: "1rem 3rem",
        borderRadius: ".7rem",
      }}
    >
      {!showVerifyOtp && (
        <form autoComplete="off" onSubmit={generateOtp}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography variant="subtitle2">
              Enter your registered mail address to get an OTP
            </Typography>
          </Stack>

          <Box py={4}>
            <TextField
              placeholder="Enter your registered E-mail ID"
              variant="outlined"
              fullWidth
              value={email}
              onBlur={emailValidator}
              onChange={(event) => {
                setInvalidEmail(false);
                setEmail(event.target.value.toLowerCase());
              }}
              helperText={invalidEmail && "Enter valid email id"}
            />
          </Box>
          <Stack direction="row" gap={3} justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!email || invalidEmail}
            >
              Submit
            </Button>
            <Link to="/login">
              <Button variant="outlined" color="primary" href="/login">
                Cancel
              </Button>
            </Link>
          </Stack>
        </form>
      )}
      {showVerifyOtp && (
        <div className="position-relative">
          <Typography
            textAlign="center"
            className="my-3"
            variant="subtitle2"
            align="center"
          >
            Please enter the 6 digit code sent your mail to confirm for password
            change
          </Typography>
          <form autoComplete="off" onSubmit={verifyOtp}>
            <Box py={1}>
              <OtpInput
                value={otp}
                onChange={(val) => setOtp(val)}
                numInputs={6}
                separator={<span className="seperator"></span>}
                isInputNum={true}
                isInputSecure={true}
                inputStyle={"otp txt-grey-drk "}
                containerStyle={"justify-content-center mb-3"}
                focusStyle={"otp-focus"}
              />
            </Box>

            <Stack
              direction="row"
              justifyContent="center"
              textAlign="center"
              gap={".2rem"}
              className="txt-md mb-3"
            >
              {resendOtpCount <= 3 ? (
                <>
                  Didn't receive a code?{" "}
                  <CountdownTimer
                    initialTime={30}
                    onResend={generateOtp}
                    updateCounter={setResendOtpCount}
                    counter={resendOtpCount}
                  />
                </>
              ) : (
                <Typography color="error" variant="caption" textAlign="center">
                  OTP limit exceeded. Please try again.
                </Typography>
              )}
            </Stack>

            <Stack direction="row" gap={3} justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={otp.length < 6}
              >
                Verify
              </Button>

              <Link to="/login">
                <Button variant="outlined" color="primary" href="/login">
                  Cancel
                </Button>
              </Link>
            </Stack>
          </form>
        </div>
      )}
      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </Paper>
  );
};

export default ForgotPassword;
