import React, { useContext } from "react";
import {
  Checkbox,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  Stack,
} from "@mui/material";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as UnCheckIcon } from "assets/images/uncheck.svg";
import { ReactComponent as CheckIcon } from "assets/images/checked.svg";
import { ReactComponent as DownloadIcon } from "assets/images/Downloads.svg";
import { ReactComponent as EditIcon } from "assets/images/tableedit.svg";
import { ReactComponent as DeleteIcon } from "assets/images/list_delete.svg";
import "./ListViewTable.scss";
import { checkBoolean, convertSecondsToHMS } from "utils/videoUtils";
import { Paper, Typography } from "@material-ui/core";
import { GlobalContext } from "context";

const CustomCheck = ({ ...rest }) => {
  return (
    <Checkbox
      checkedIcon={<CheckIcon width={"15px"} />}
      icon={<UnCheckIcon width={"15px"} />}
      {...rest}
    />
  );
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "cont_name",
    numeric: false,
    disablePadding: true,
    label: "CONTRACTOR NAME",
  },
  {
    id: "file_name",
    numeric: false,
    disablePadding: false,
    label: "VIDEO NAME",
  },
  {
    id: "approval_status",
    numeric: false,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "created_on",
    numeric: false,
    disablePadding: false,
    label: "CREATED ON",
  },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "DURATION",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isAdmin,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow sx={{ cursor: "pointer" }}>
        {isAdmin && (
          <TableCell padding="checkbox" sx={{ width: "70px" }}>
            <CustomCheck
              size="small"
              color="error"
              disableRipple
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              sx={{ p: 0.5 }}
            />
          </TableCell>
        )}

        {headCells
          .filter((headCell) => isAdmin || headCell.id !== "actions")
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <Stack
                color="var(--text-primary)"
                direction={"row"}
                alignItems={"center"}
                gap={1}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {headCell.id !== "actions" && <SortIcon width={"8px"} />}
              </Stack>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

const ListViewTable = ({
  page,
  selected,
  setSelected,
  rows,
  rowVideoDownload,
  rowVideoDelete,
  handleRenamePopup,
  handleNavigate,
}) => {
  const {
    state: { showLoader, isAdmin },
  } = useContext(GlobalContext);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const rowsPerPage = 5;

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event?.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%", mb: 2, mt: 1 }}>
      <TableContainer
        // className={`downloadTableBox`}
        id="listViewTableBox"
        component={Paper}
        style={{ border: "none" }}
        variant="outlined"
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            isAdmin={checkBoolean(isAdmin)}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  onClick={() => {
                    handleNavigate(row);
                  }}
                >
                  {checkBoolean(isAdmin) && (
                    <TableCell padding="checkbox">
                      <CustomCheck
                        size="small"
                        disableRipple
                        color="error"
                        checked={isItemSelected}
                        sx={{ p: 0.5, cursor: "pointer" }}
                        onClick={(event) => handleClick(event, row.id)}
                      />
                    </TableCell>
                  )}

                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {row.cont_name}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {row?.file_name || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                    className={
                      row.approval_status === true
                        ? "approved"
                        : row.approval_status === false
                        ? "rejected"
                        : "not-reviewed"
                    }
                    x
                  >
                    {row.approval_status === true
                      ? "Approved"
                      : row.approval_status === false
                      ? "Rejected"
                      : "Not reviewed"}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {row.created_on}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {convertSecondsToHMS(row?.duration) || "-"}
                  </TableCell>
                  {checkBoolean(isAdmin) && (
                    <TableCell
                      style={{
                        color: "var(--text-primary)",
                      }}
                    >
                      <Stack direction="row" gap={2}>
                        <DownloadIcon
                          onClick={(e) => {
                            e?.stopPropagation();
                            rowVideoDownload([row?.id]);
                          }}
                          style={{ width: "15px", cursor: "pointer" }}
                        />
                        <EditIcon
                          onClick={(e) => {
                            e?.stopPropagation();
                            handleRenamePopup(row?.id);
                          }}
                          style={{ width: "15px", cursor: "pointer" }}
                        />
                        <DeleteIcon
                          onClick={(e) => {
                            e?.stopPropagation();
                            rowVideoDelete([row?.id]);
                          }}
                          style={{
                            width: "15px",
                            cursor: "pointer",
                            position: "relative",
                            top: "3px",
                          }}
                        />
                      </Stack>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}

            {!visibleRows?.length && !showLoader && (
              <TableRow
                style={{
                  height: 35 * 5,
                }}
              >
                <TableCell
                  colSpan={10}
                  align="center"
                  style={{ borderBottom: "none" }}
                >
                  <Typography variant="h6">No videos Found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListViewTable;
