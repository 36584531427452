import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Stack,
  Collapse,
} from "@mui/material";
import { Checkbox, InputField, Snackbar } from "components";
import "./FreelancerFilterPopover.scss";
import { actions, GlobalContext } from "context";
import { useContext, useEffect, useState } from "react";
import AuthServices from "api/services/auth-services";
import { getErrorMessage } from "utils/validator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function FreelancerFilterPopover({
  anchorEl,
  handleClose,
  handleFilterChange,
  check,
  handleLocationFilters,
  locationCheck,
}) {
  const role = ["active", "inactive"];

  const { dispatch } = useContext(GlobalContext);

  const [openCollapse, setOpenCollapse] = useState(false);

  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState("");

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const handleToggleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleSearchChange = (value) => {
    handleFilterChange(value);
  };

  const handleContractorSearchChange = (value) => {
    handleLocationFilters(value);
  };

  const getContractorList = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getContractorLocationList();
      setLocationList(data);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const ERROR_MSG = getErrorMessage(err);
      setShowSnackBar(true);
      const content = {
        message: ERROR_MSG,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleSearchContractor = (value) => {
    setLocation(value);
  };

  const filteredContractors =
    location === ""
      ? locationList
      : locationList.filter((item) =>
          item?.toLowerCase()?.includes(location.toLowerCase())
        );

  useEffect(() => {
    getContractorList();
  }, []);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Box className="Download-popover-root">
      <Popover
        id={"freelancer-filter-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: "20rem",
            },
          },
        }}
      >
        <div className="popover-content" style={{ minWidth: 180 }}>
          <List>
            {role.map((name) => (
              <ListItem style={{ cursor: "pointer" }}>
                <ListItemText
                  primary={
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={".8rem"}
                      >
                        <Typography
                          variant="body2"
                          textTransform={"capitalize"}
                        >
                          {name}
                        </Typography>
                        <Checkbox
                          size="small"
                          checked={check.includes(name)}
                          onChange={() => handleSearchChange(name)}
                        />
                      </Stack>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>

        <div className="popover-content" style={{ maxWidth: 180 }}>
          <Typography
            variant="subtitle1"
            fontSize={".9rem"}
            sx={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              cursor: "pointer",
              py: 0.5,
              px: 1,
            }}
            onClick={handleToggleCollapse}
          >
            Location
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Typography>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box display={"flex"} alignItems={"center"} my={0.5}>
              <InputField
                variant="outlined"
                className={`p-0 m-0`}
                placeholder="Search by location"
                withAdronment={true}
                value={location}
                onChange={(e) => handleSearchContractor(e.target.value)}
              />
            </Box>

            <List>
              {filteredContractors?.map((name, ind) => (
                <ListItem key={ind}>
                  <ListItemText
                    primary={
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px=".8rem"
                      >
                        <Typography
                          variant="body2"
                          textTransform={"capitalize"}
                        >
                          {name}
                        </Typography>
                        <Checkbox
                          size="small"
                          checked={locationCheck?.includes(name)}
                          onChange={() => handleContractorSearchChange(name)}
                        />
                      </Stack>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      </Popover>

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </Box>
  );
}

export default FreelancerFilterPopover;
